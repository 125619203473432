import type { ObjectStore } from 'o365.pwa.declaration.shared.dexie.objectStores.ObjectStore.d.ts';
import type { Dexie } from 'o365.pwa.declaration.sw.dexie.d.ts';

declare module 'o365.pwa.declaration.shared.dexie.objectStores.Database.d.ts' {
    export interface IObjectStoreUpdate {
        schema: string;
        objectStoreName: string;
    }

    export interface IObjectStores {
        getAll: () => Promise<Array<ObjectStore>>;
    }

    export type ObjectStores = IObjectStores & Record<Exclude<string, keyof IObjectStores>, Promise<ObjectStore>>;

    export class Database {
        static objectStoreDexieSchema: string;

        id: string;
        appId: string;
        version: number;
        installedSchema: { [key: string]: string };

        constructor(id: string, appId: string);

        readonly name: string;
        readonly objectStores: ObjectStores;
        readonly databaseSchema: Promise<{ [key: string]: string }>;
        readonly upgradeNeeded: Promise<boolean>;
        readonly dexieInstance: Promise<Dexie>;

        save(): Promise<void>;
        delete(): Promise<void>;
        forceReload(): Promise<Database | null>;
        initialize(): Promise<void>;
    }
}
